import React from "react";
import {Link, graphql} from "gatsby";
import Layout from "../components/layout";
import GetInvolved from "../components/getinvolved";
import Hero from "../components/hero";
import Img from 'gatsby-image';
import SEO from "../components/seo";
import { useTranslation } from "react-i18next";
import { node } from "prop-types"
import styled from 'styled-components';
import urlFor from "../helper/urlFor";
import { getFluidGatsbyImage } from 'gatsby-source-sanity';

const ProjectsStyle = styled.div`
 padding: 100px;
  @media (max-width: 768px) {
    padding: 100px 40px;
  }
  h2 {
    text-align: center;
    font-size: 3.5rem;
    padding-bottom: 5rem;
    color: #22577A;
    @media (max-width: 425px) {
      font-size: 2.5rem;
      padding-bottom: 2rem;
    }
  }
  h3 {
  font-size: 2rem;
  color: #14213D;
    @media (max-width: 425px) {
      font-size: 1.7rem;
    }
  }
  h4 {
    font-size: 1.2rem;
  }
`;

const ProjectStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 10rem;
  @media (max-width: 768px) {
    padding-bottom: 5rem;
  }
  a {
    text-decoration: none;
  }
  p {
    padding-bottom: 1rem;
    font-size: 1.2rem;
    line-height: 1.5;
  }
  :nth-child(odd) {
    flex-direction: row-reverse;
    > .details {
      padding-left: 4rem;
      padding-right: 0;
      text-align: right;
      @media (max-width: 425px) {
        padding: 0;
      }
    }
    > .gatsby-image-wrapper {
      box-shadow: -20px 20px #FFCE7E;
    }
  }
  > .details {
    flex: 2;
    padding-right: 4rem;
    @media (max-width: 425px) {
      flex: 1 100%;
      order: 2;
      padding-right: 0;
    }
  }
  > .gatsby-image-wrapper {
    flex: 1;
    box-shadow: 20px 20px #14213D;
    @media (max-width: 425px) {
        flex: 1 100%;
        order: 1;
        margin-bottom: 3rem;
    }
  }
`;

const DemoStyles = styled.div`
  h2 {
    font-size: 1.75rem;
    padding: 1.5rem 0;
  }
  
  p {
    padding-bottom: 1.4rem;
    font-size: 1rem;
  }

  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    overflow: hidden;
    @media (max-width: 425px) {
      width: auto;
    }
  }
  thead th {
    width: 35%;
    background-color: #FFCE7E;
    color: #21577a;
    font-size: 1.05rem;
    &:first-child {
      width: 25%;
    }
  }

  th, td {
    padding: 20px;
  }
  
  td:not(:first-child) {
    text-align: right;
  }

  tr:nth-child(even) {
    background-color: #dadadaa1;
  }

  td:nth-child(2),
  thead th:nth-child(2) {
    border-left: 1px solid rgba(0, 0, 0, 0.08);
    border-right: 1px solid rgba(0, 0, 0, 0.08);
  }

  span {
    font-size: 0.8rem;
    text-align: right;
    display: block;
    padding-top: .5rem;
  }
`;

const MobileTable = styled.div`
  @media (max-width: 425px) {
    overflow-x: scroll;
  }
`;

const sanityConfig = {projectId: '7nqypbju', dataset: 'production'}

const WorkPage = ({data}) => {
  const { i18n } = useTranslation("common");
  const projects = data.page._rawContent.filter(content => content._type === "localeTextImage");
  const hero = data.page._rawContent.filter(content => content._type === "hero");
  return (
    <Layout>
      <SEO title="Our Work" />
      <Hero title={i18n.language === "en" ? hero[0].heading.en : hero[0].heading.ph} bg={urlFor(hero[0].bgImage.asset)}/>
      <ProjectsStyle>
        <h2>Current Projects</h2>
        {projects.map((project, index) => (
          <ProjectStyle key={index}>
            <div className="details">
            {
              i18n.language === "en" ?
              <>
                <h3>{project.heading.ph}</h3>
                <h4>{project.heading.en}</h4>
              </> :
              <h3>{project.heading.ph}</h3> 
            }

            {i18n.language === "en" ? 
              <p>{project.description.en}</p>
            : <p>{project.description.ph}</p>}

            {i18n.language === "en" ? 
              <Link to={"/"+ i18n.language + "/get-involved"} className="button">
                Donate {index === 0 ? 
                  "Milk" : 
                  "Vegetable Seeds"}
              </Link> :
              <Link to={"/"+ i18n.language + "/get-involved"} className="button">
                Magbigay ng {index === 0 ? 
                  "Gatas" : 
                  "Mga Binhi ng Gulay"}
              </Link>
            }
            </div>
          <Img fluid={getFluidGatsbyImage(project.image.asset._ref, {maxWidth: 1000}, sanityConfig)} alt={project.heading.name} />
          </ProjectStyle>
        ))}
        <DemoStyles>
          <h2>{data.table.name}</h2>
          <p>{i18n.language === 'en' ? data.table.description.en : data.table.description.ph}</p>
          <MobileTable>
            <table>
              <thead>
                <tr>
                  {data.table.table.rows[0].cells.map((cell, index) => {
                    return (<th key={index}>{cell}</th>)
                  })}
                </tr>
              </thead>
              <tbody>
                  {data.table.table.rows.slice(1).map((row, index) => {
                  return (
                    <tr key={index}>
                        {row.cells.map(cell => {
                          return (
                            <td>{cell}</td>
                          )
                        })}
                    </tr>)
                  })}
              </tbody>
            </table>
          </MobileTable>
          <span>* Data is from the Office of Indigenous Affairs, Capas, Tarlac</span>
        </DemoStyles>
      </ProjectsStyle>
      <GetInvolved />
    </Layout>
  )
}

export const query = graphql`
query {
  page: sanityPages(name: {eq:"Our Work"}) {
    _rawContent
  }
  table: sanityTables(name: {eq: "DEMOGRAPHICS OF AAPAFINC AETA OUTREACH INITIATIVES"}) {
    name
    table {
      rows {
        cells
      }
    }
    description {
      en
      ph
    }
  }
}`;

export default WorkPage
